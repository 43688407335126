import React from 'react'
import PropTypes from 'prop-types'
import { Link }  from 'gatsby'

import styled from 'styled-components'
import scssVars from '~components/scss-variables'

const Wrapper = styled.div`
  background: url(${(p) => p.srcMobile}) no-repeat center / cover;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 10%;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    background: url(${(p) => p.src}) no-repeat center / cover;
    height: 600px;
    div {
      background-color: ${scssVars.white};
      box-shadow: 0 0 1px rgba(0,0,0,0.5);
      width: 45%;
      padding: 10px 20px;
      height: auto;
      max-width: 450px;
    }
  }
`

const StyledTitle = styled.h3`
  font-size: 22px;
  line-height: 26.4px;
  font-weight: 700;
  color: ${scssVars.lightGray};
  margin-bottom: 1rem;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    color: ${scssVars.darkBrown};
    margin-top: 2rem;
  }
`

const StyledDescription = styled.p`
  font-family: 'Montserrat', Book;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.8px;
  font-weight: 100;
  color: ${scssVars.lightGray};
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    color: ${scssVars.darkBrown};
    margin: 0 auto;
  }
`

const StyledLink = styled(Link)`
  width: 60% !important;
  margin: 5% auto;
`

const StyledSection = styled.section`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-top: 5%;
  }
`

const ReadyToStart = ({ src, srcMobile, className }) => (
  <StyledSection>
    <Wrapper className={className} src={src} srcMobile={srcMobile}>
      <div>
        <StyledTitle>Ready For Your Service Estimate?</StyledTitle>
        <StyledDescription>
          Use our Repair Wizard to discover our full range of services, get a repair estimate and start a mail-in or local pick-up order online.
        </StyledDescription>
        <StyledLink to="/start-a-repair" className="btn btn-primary">
          Start A Repair
        </StyledLink>
      </div>
    </Wrapper>
  </StyledSection>
)

ReadyToStart.propTypes = {
  src: PropTypes.string.isRequired,
  srcMobile: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default ReadyToStart
