import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

import Main from '~components/layouts/Main'
import SEO from '~components/seo'
import HeroSection from '~components/layouts/marketing/HeroSection'
import Layout from '~components/Layout'
import scssVars from '~components/scss-variables'
import ReadyToStart2 from '~components/Shared/ReadyToStart2'
import HorizontalRule from '~components/LayoutComponents/HorizontalRule'

import footerImg from '../../../static/img/contact/footer-desktop@2x.jpg'

const StyledLead = styled.h2`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: ${scssVars.darkBrown};
`

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${scssVars.darkBrown};
  svg {
    margin-left:1rem;
    margin-right: 1rem;
    margin-top: 4px;
  }
  a {
    font-family: 'EB Garamond', serif;
    color: ${scssVars.darkBrown};
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: 0.54px;
    line-height: 24px;
  }
  div {
    font-family: 'EB Garamond', serif;
    color: ${scssVars.darkBrown};
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: 0.54px;
    line-height: 24px;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-left: -15px;
    a {
      font-size: 16px;
    }
  }
`

const ContactList = styled.ul`
  color: ${scssVars.darkBrown};
  line-height: 24px;
  padding-left: 15px;
  li {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    a {
      font-family: 'EB Garamond', serif;
      color: ${scssVars.darkBrown};
      font-weight: 400;
      font-size: 16px;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
`

const StyledInteractDiv = styled.div`
  border: 2px solid ${scssVars.orange};
  padding-top: 2rem;
  padding-bottom: 1rem;
`

const StyledAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${scssVars.darkBrown};
  p {
    margin-bottom: 0;
    &:first-child {
      margin-top: 2rem;
    }
    &:first-child, &:nth-child(2) {
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
      line-height: 24px;
    }
    &:nth-child(2), &:nth-child(5) {
      margin-bottom: 1rem;
    }
    &:last-child {
      margin-bottom: 2rem;
    }
  }
  @media only screen and (min-width: ${scssVars.mobileMax}) {
    width: 75%;
    margin: 0 auto;
  }
`

const InteractWrapper = styled.div`
  position: relative;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  svg {
    width: 32.4px !important;
    height: 27.77px !important;
  }
  p {
    &:first-child {
      background-color: white;
      font-size: 20px;
      font-weight: 700;
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      margin-top: -2%;
      left: 20%;
      width: 60%;
    }
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 22px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    width: 75%;
    margin: 2.5rem auto 1.5rem;
    p {
      &:first-child {
        width: auto;
        padding: 0 10px;
        margin: -1% auto 0;
        margin-left: auto;
        text-align: center;
        left: 50%;
        margin-left: -25%;
      }
      line-height: 18px;
    }
  }
`

const ContactWrapper = styled.div`
  position: relative;
  h2 {
    color: ${scssVars.darkBrown};
    font-weight: 700;
    font-size: 21px;
    line-height: 1.125;
  }
  &::after {
    content: ' ';
    display: inline-block;
    position: absolute;
    width: 255px;
    height: 180px;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${scssVars.lightGray};
    bottom: -25px;
    z-index: -1;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    &::after {
      width: 500px;
      height: 125%;
      right: 0;
      bottom: -5px;
    }
  }
`

const CompanyList = styled.ul`
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  li {
    margin-bottom: 15px;
    font-size: 16px;
    a {
      font-family: 'EB Garamond', serif;
      color: ${scssVars.darkBrown};
    }
    strong {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      font-weight: 700px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.48px;
    }
  }
`

const TextAnchor = styled.a`
  font-family: 'EB Garamond', serif;
  color: ${scssVars.darkBrown};
`

export default function ContactPage () {
  return (
    <Layout>
      <SEO title="Contact Us" pathname="/contact" />
      <Main>
        <HeroSection title={'Contact Us'}
          fluid={false}
          mobileImg={'https://leatherspa.imgix.net/hero-desktop@2x.jpg?fit=scale&w=992'}
          desktopImg={'https://leatherspa.imgix.net/hero-desktop@2x.jpg?fit=scale&w=1360'}>
          <>
            <StyledLead>WE LOVE HEARING FROM YOU.</StyledLead>
            <p>
              Whether you want to check on an order, get advice from our experts, or ask about our products, we are there for you to make your repair and care needs easy and hassle-free. You can contact us by phone, email or chat.
            </p>
          </>
        </HeroSection>
        <Container className="mt-4">
          <Row>
            <Col lg={{ size: 6, order: 1 }} sm={{ size: 12 }}>
              <ContactWrapper className="mb-5">
                <Row className="d-flex flex-row w-100">
                  <Col className="flex-grow-1">
                    <h2>LEATHER SPA<br />CUSTOMER SERVICE:</h2>
                  </Col>
                  <Col className="flex-grow-0 d-flex flex-column justify-content-center">
                    <div style={{minWidth:'250px'}}>
                      <ContactInfo>
                        <FontAwesomeIcon icon={faPhone} color={scssVars.darkBrown} />
                        <a href="tel:+18779693550">877.969.3550</a>
                      </ContactInfo>
                      <ContactInfo>
                        <FontAwesomeIcon icon={faEnvelope} color={scssVars.darkBrown} />
                        <a href="mailto:info@leatherspa.com">info@leatherspa.com</a>
                      </ContactInfo>
                      <ContactInfo>
                        <FontAwesomeIcon icon={faClock} color={scssVars.darkBrown} />
                          <div>Monday – Friday, 10am - 5pm</div>
                      </ContactInfo>
                    </div>
                  </Col>
                </Row>
              </ContactWrapper>
              <HorizontalRule className="d-none my-3 my-lg-4 d-lg-block" />
              <ContactList>
                <li>
                  <strong className="text-uppercase">Customer Service:</strong>
                  &nbsp;
                  <a href="mailto:info@leatherspa.com">info@leatherspa.com</a>
                </li>
                <li>
                  <strong className="text-uppercase">Corporate Accounts And Billing</strong>
                  &nbsp;
                  <a href="mailto:office@leatherspa.com">office@leatherspa.com</a>
                </li>
                <li>
                  <strong className="text-uppercase">Press:</strong>
                  &nbsp;
                  <a href="mailto:press@leatherspa.com">press@leatherspa.com</a>
                </li>
                <li>
                  <strong className="text-uppercase">Events:</strong>
                  &nbsp;
                  <a href="mailto:events@leatherspa.com">events@leatherspa.com</a>
                </li>
                <li>
                  <strong className="text-uppercase">Partnerships And General Inquiries:</strong>
                  &nbsp;
                  <a href="mailto:hello@leatherspa.com">hello@leatherspa.com</a>
                </li>
              </ContactList>
              <HorizontalRule className="my-3 my-lg-4" />
              <CompanyList>
                <li>
                  <strong className="text-uppercase">Careers</strong>
                  <br />
                  For employment opportunities, please visit our careers page on <a href="https://www.indeedjobs.com/leather-spa" target="_blank" rel="me noopener noreferrer">Indeed</a> to view current job opening or email your resume and cover letter to: <a href="mailto:jobs@leatherspa.com">jobs@leatherspa.com</a>
                </li>
                <li>
                  <strong className="text-uppercase">Business And Corporate Services</strong>
                  <br />
                  Leather Spa provides high-quality repair and care services for luxury businesses. Visit our <Link to="/customer-services/corporate">corporate services</Link> page to find out more.
                </li>
                <li>
                  <strong className="text-uppercase">Accessibility</strong>
                  <br />
                  If you are having any difficulty using this website, please contact the Help Desk at <a href="mailto:info@leatherspa.com">info@leatherspa.com</a> or <a href="tel:+17183929220">(718) 392-9220</a>. Please identify the web page address or URL and the specific problems you have encountered and we will address the issue or assist you over the phone.
                </li>
              </CompanyList>
            </Col>
            <Col className="px-0 px-lg-3" lg={{ size: 6, order: 2 }} sm={{ size: 12 }}>
              <InteractWrapper className="px-3 px-lg-0">
                <p>INTERACT WITH US</p>
                <StyledInteractDiv>
                  <FontAwesomeIcon icon={faTwitter} color={scssVars.orange} />
                  <p>
                    Got any repair questions? Our repair advisors are at your service.&nbsp;
                    <TextAnchor href="https://twitter.com/leatherspa" target="_blank" rel="me noopener noreferrer">
                      Tweet us at @LeatherSpa
                    </TextAnchor>
                  </p>
                  <FontAwesomeIcon icon={faInstagram} color={scssVars.orange} />
                  <p>
                    Got any repair questions? Our repair advisors are at your service.&nbsp;
                    <TextAnchor href="https://www.instagram.com/leatherspa" target="_blank" rel="me noopener noreferrer">
                      DM us at @leatherspa
                    </TextAnchor>
                  </p>
                </StyledInteractDiv>
              </InteractWrapper>
              <StyledAddressWrapper className="bg-clr-cararra">
                <p>LEATHER SPA</p>
                <p>CORPORATE OFFICE:</p>
                <p>22-19 41st Ave, 7th Floor <br />Long Island City, NY 11101</p>
                <p>Phone: (718) 392-9220</p>
                <p>Fax: (718) 392-9224</p>
                <p>OFFICE HOURS:</p>
                <p>Monday – Friday, 9am – 5pm</p>
              </StyledAddressWrapper>
            </Col>
          </Row>
        </Container>
        <ReadyToStart2 src={footerImg} srcMobile={footerImg} />
      </Main>

    </Layout>
  )
}
