import styled from 'styled-components'
import scssVars from '~components/scss-variables'

const HorizontalRule = styled.hr`
  width: ${props => props.width || '100%'};
  border-color: ${props => props.color || scssVars.darkBrown};
  border-width: ${props => props.borderWidth || '2px'};
`

export default HorizontalRule
